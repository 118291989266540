@import './theme.scss';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'apps/cs/src/variables.scss';

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import 'codemirror/lib/codemirror';
@import 'codemirror/theme/material';
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
/*_______________________________________________________
|                                                        |
|                 BACKGROUND / BORDERS                   |
|________________________________________________________|
*/

mat-form-field {
  width: 100%;
}

.mr-3 {
  margin-right: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

:root {
  --pulpo-color: #035e7b;
  --background-image: 'toBeDefined';
  --pulpo-color-disabled: rgb(194, 194, 194);
}
.bold {
  font-weight: bold !important;
}
.background,
.background-hover,
.background-hover:focus {
  background-color: $pulpocolor !important;
  color: white;
}

.background-hover:hover {
  background-color: $pulpocolor !important;
  color: white;
}

.background-light {
  background-color: rgb(116, 184, 207) !important;
  color: white;
}


.corners {
  border-radius: 5px;
}

.corners-br {
  border-bottom-right-radius: 5px;
}

.corners-b {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.corners-br {
  border-bottom-right-radius: 5px;
}

.corners-bl {
  border-bottom-left-radius: 5px;
}

.corners-t {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.warning {
  background-color: rgba(255, 140, 0, 0.75);
  color: #fff;
}

.valid {
  background-color: rgb(26, 255, 0);
  color: #000000;
}

.invalid {
  background-color: rgb(255, 0, 0);
  color: #fff;
}

/*_______________________________________________________
|                                                        |
|                       PADDING                          |
|________________________________________________________|
*/
.col-12,
.row {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

/*_______________________________________________________
|                                                        |
|                       POINTER                          |
|________________________________________________________|
*/
.pointer {
  cursor: pointer;
}

/*_______________________________________________________
|                                                        |
|                      BOX SHADOW                        |
|________________________________________________________|
*/
.box {
  box-shadow: 2px 2px 10px gray;
  -moz-box-shadow: 2px 2px 10px gray;
  -webkit-box-shadow: 2px 2px 10px gray;
}

/*_______________________________________________________
|                                                        |
|                  SHOW/HIDE  Position                   |
|________________________________________________________|
*/

.hide {
  display: none;
  animation: 0.5s move;
}

.overflow-hidden {
  overflow: hidden;
}

.on-hover:hover + .hide,
.hide:hover {
  display: block;
}

.relative {
  position: relative;
  z-index: 0;
}

.absolute {
  position: absolute;
  z-index: 1;
}

/*_______________________________________________________
|                                                        |
|                        COLOR                           |
|________________________________________________________|
*/

.grey {
  color: gray;
}

.white {
  color: white !important;
}

.white-bckg-op-4 {
  background-color: white;
  opacity: 0.4;
}

.white-bckg-op-8 {
  background-color: white;
  opacity: 0.8;
}

.background-light {
  background-color: rgba(3, 94, 123, 0.5) !important;
  color: white;
}

.background-xlight {
  background-color: rgba(3, 94, 123, 0.2) !important;
  color: white;
}

.bordered {
  border: solid rgba(3, 94, 123, 1) 1px !important;
}

.bordered-w {
  border: white 1px !important;
}

.table-header {
  background: $pulpocolor;
  color: $lightcolor;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0 20px;
}

.table-wrapper {
  align-items: flex-start;
  max-height: calc(
    60vw - 155px
  ); // or height:calc(100vh - 155px); depending on your need  change
  overflow: auto;

  .pulpo-table {
    th.mat-header-cell, th.mat-mdc-header-cell {
      font-size: 16px;
      background-color: $pulpocolor;
      color: $lightcolor;
      font-weight: bold;
    }

    tr.mat-header-row, tr.mat-mdc-header-row {
      height: 45px;
    }

    tr.mat-footer-row,
    tr.mat-row {
      max-height: 2.5vw !important;
    }

    tr.row-land-only {
      background-color: rgba(185, 199, 210, 0.77) !important;
    }

    tr.row-catalogue {
      background-color: rgba(185, 199, 210, 0.77) !important;
    }
  }

  button {
    outline: none;
    border: none;
  }
}

/*
|========================================================|
|                                                        |
|                       ANIMATION                        |
|________________________________________________________|
*/

.zoom:hover {
  transition: transform 0.5s ease;
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  z-index: 1000;
}

.zoom-1:hover {
  transition: transform 0.5s ease;
  transform: scale(
    1.3
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  z-index: 1000;
}

.scale1-5 {
  transform: scale(1.5);
}

.background-light {
  background-color: rgba(3, 94, 123, 0.5) !important;
  color: white;
}





.btn-orange {
  background-color: darkorange !important;
  color: white !important;
  outline: none !important;
  border: none !important;
}

.btn-orange:hover {
  background-color: #ffba40 !important;
}


.btn-disabled {
  background-color: darkgray !important;
  pointer-events: none;
  outline: none !important;
  border: none !important;
}

.btn-disabled-icon {
  color: darkgray !important;
  pointer-events: none;
  outline: none !important;
  border: none !important;
}

.sticky-button {
  z-index: 100;
  position: fixed;
  bottom: 20px;

  .btn-disabled {
    background-color: darkgray !important;
    pointer-events: none;
  }

  .btn-enabled:hover {
    background-color: rgb(0, 202, 241) !important;
    box-shadow: rgb(172, 237, 255) !important;
    color: #fff;
  }

  .btn-enabled-red {
    background-color: rgb(241, 0, 0) !important;
    color: #fff;
  }

  .btn-enabled-red:hover {
    background-color: rgb(255, 146, 146) !important;
    box-shadow: rgb(255, 172, 172) !important;
    color: #fff;
  }
  .btn {
    width: 140px;
    height: 45px;
    border-radius: 45px;
  }

  .full-width {
    width: 75% !important;
    color: $pulpocolor !important;

    :host ::ng-deep label {
      color: $pulpocolor !important;
    }

    :host ::ng-deep .mat-form-field {
      color: $pulpocolor !important;
    }

    :host ::ng-deep .mat-select-value {
      color: $pulpocolor !important;
    }

    :host ::ng-deep .mat-select-label {
      color: $pulpocolor !important;
    }
  }
}

.custom-modal .modal-dialog {
  max-width: calc(100vw - 500px);
}

input:focus:invalid {
  box-shadow: none;
  border: 0 solid red !important;
  outline: none;
}

input.ng-invalid:not(form) {
  border: none !important;
  box-shadow: none;
}

.scale-2 {
  transform: scale(2);
}

.scale-4 {
  transform: scale(4);
}

.sticky-button-product {
  z-index: 100;
  position: fixed;
  top: 70%;
  right: 15%;
  transform: scale(1.25);
  .btn-enabled:hover {
    background-color: rgb(0, 202, 241) !important;
    box-shadow: rgb(172, 237, 255) !important;
    color: #fff;
  }
  .btn-enabled-red {
    background-color: rgb(241, 0, 0) !important;
    color: #fff;
  }
  .btn-enabled-red:hover {
    background-color: rgb(255, 146, 146) !important;
    box-shadow: rgb(255, 172, 172) !important;
    color: #fff;
  }
  .btn {
    width: 140px;
    height: 45px;
    border-radius: 45px;
  }
}

.btn-enabled-red:hover {
  background-color: rgb(255, 146, 146) !important;
  box-shadow: rgb(255, 172, 172) !important;
  color: #fff;
}

.sticky-button-ml {
  z-index: 100;
  position: fixed;
  top: 50%;
  right: 40px;
  transform: scale(1.25);
  .btn-enabled:hover {
    background-color: rgb(0, 202, 241) !important;
    box-shadow: rgb(172, 237, 255) !important;
    color: #fff;
  }
  .btn-enabled-red {
    background-color: rgb(241, 0, 0) !important;
    color: #fff;
  }
  .btn-enabled-red:hover {
    background-color: rgb(255, 146, 146) !important;
    box-shadow: rgb(255, 172, 172) !important;
    color: #fff;
  }
  .btn {
    width: 140px;
    height: 45px;
    border-radius: 45px;
  }
}

.sticky-button-bl {
  z-index: 100;
  position: fixed;
  bottom: 20px;
  right: 40px;
  transform: scale(1.25);

  .btn-enabled:hover {
    background-color: rgb(0, 202, 241) !important;
    box-shadow: rgb(172, 237, 255) !important;
    color: #fff;
  }

  .btn-enabled-red {
    background-color: rgb(241, 0, 0) !important;
    color: #fff;
  }

  .btn-enabled-red:hover {
    background-color: rgb(255, 146, 146) !important;
    box-shadow: rgb(255, 172, 172) !important;
    color: #fff;
  }
  .btn {
    width: 140px;
    height: 45px;
    border-radius: 45px;
  }
}

.fixed-height-100 {
  height: 100px !important;
}

.fixed-height-150 {
  height: 150px !important;
}

.fixed-height-500 {
  height: 500px !important;
}

.fixed-height-1000 {
  height: 1000px !important;
}

.fixed-height-250 {
  height: 250px !important;
}

.fixed-height-400 {
  height: 400px !important;
}

.fixed-height-75 {
  height: 75px !important;
}

.fixed-height-ws {
  height: 3vw !important;
  display: flex;
  align-items: center;
  text-align: center;
}

.w-30 {
  width: 30% !important;
}

.w-45 {
  width: 45% !important;
}

.w-85 {
  width: 85% !important;
}

.tab-group-500 {
  min-height: 500px;
  height: 500px !important;
}

.tab-group-1000 {
  min-height: 1000px;
  height: 1000px !important;
}

.highlight {
  background: #42a948; /* green */
}

angular-editor {
  b,
  strong {
    font-family: inherit;
    font-weight: bold;
  }
}

/* ==============================================================
Bootstrap tweaks
===============================================================*/

body,
h1,
h2,
h3,
h4,
h5,
h6,
span,
p {
  font-weight: 300;
  font-family: system-ui, sans-serif;
}

/* Increase contrast of links to get 100% on Lighthouse Accessability Audit. Override this color if you want to change the link color, or use a Bootswatch theme */
a,
a:hover {
  color: #333333;
  //font-weight: bold;
}

a:hover {
  /* make sure browsers use the pointer cursor for anchors, even with no href */
  cursor: pointer;
}

.dropdown-item:hover {
  color: $dropdown-link-hover-color;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* other generic styles */

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-10 {
  width: 10% !important;
}

.w-5 {
  width: 5% !important;
}

.h-75 {
  height: 75% !important;
}

.h-50 {
  height: 50% !important;
}
.h-80 {
  height: 80% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.spaced-table {
  border-spacing: 10px;
  border-collapse: separate;
  display: table;
  table-layout: fixed;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;
    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }
    .popover pre {
      font-size: 10px;
    }
  }
  .toast {
    position: fixed;
    width: 100%;
    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
    }
    &.top {
      top: 55px;
    }
    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

/* ==========================================================================
top grey container & wrapper
========================================================================== */
.top-container {
  background-color: #f1f3f4;
  padding: 5% 5%;
  margin-bottom: 5%;
}

.top-container-lg {
  background-color: #f1f3f4;
  padding: 2% 2%;
}

.contract-container {
  min-width: 80vh;
  background-color: #f1f3f4;
  padding: 40px 0;
  margin-bottom: 40px;
  min-height: 100rem;
}
/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details > {
  dd {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      margin-bottom: 15px;
    }
    dd {
      border-bottom: 1px solid #eee;
      padding-left: 180px;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.card-img-top {
  width: 15vw;
  object-fit: cover;
}

.text-return-line {
  white-space: pre-line;
}

// Buttons
.btn {
  border-radius: 0;
}
.btn-info {
  background-color: $pulpocolor;
  border-color: $pulpocolor;
}

.btn-info:disabled {
  background-color: $pulpocolor;
  border-color: $pulpocolor;
  opacity: 0.5;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: none;
}

// Forms
.c-ui-select {
  max-width: 100%;
  border-radius: 0;
  background-color: transparent;
  border: 1px solid $pulpocolor;
  font-size: 14px;
  padding-right: 48px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: url('content/images/select-arrow.jpg');
  background-position: center right;
  background-repeat: no-repeat;
}
.c-ui-select--small {
  font-size: 12px;
}

.c-ui-input {
  max-width: 100%;
  border-radius: 0;
  background-color: transparent;
  border: 1px solid $pulpocolor;
  font-size: 14px;
  padding: 8px 16px;
}

// Header
.header {
  background-color: $pulpocolor;
  color: #ffffff !important;
  margin-left: 0;
  margin-right: 0;
}

.header__logo {
  height: 100px !important;
  width: auto;
  max-width: 150px !important;
  display: inline-block;
}

.header__profile {
  width: 55px;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  margin-left: 50% !important;
}

.header__icons {
  display: inline-block;
  vertical-align: middle;
}

// TO info insert
.to-info-insert {
  background-color: $pulpocolor;
  padding: 24px;
  border-radius: 8px;
  color: #ffffff;
}
.to-info-insert-left {
  margin-right: 48px;
}
.to-info-insert-icon-tel,
.to-info-insert-icon-mail {
  width: 20px;
  height: 20px;
  fill: #ffffff;
  margin-right: 8px;
}

// Product name
.product-name {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 16px;
}

// block titles
.block-title {
  font-size: 20px;
  font-weight: bold;
  color: $pulpocolor;
  text-align: center;
  margin-bottom: 12px;
}
.block-subtitle {
  font-size: 16px;
  font-family: system-ui, sans-serif;
  text-align: center;
}

// Block component
.c-ui-block {
  position: relative;
  background-color: #d1e4ec;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 96px;
}

.c-ui-block__label {
  position: absolute;
  margin-bottom: 0;
  display: inline-block;
  padding: 8px 16px;
  color: $pulpocolor;
  background-color: #d1e4ec;
}
.c-ui-block__label--title {
  left: 65px;
  top: -40px;
  padding-top: -50px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.c-ui-block__label--outro {
  right: 32px;
  bottom: -40px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.c-ui-block__array-labels {
  margin-top: -56px;
}
.c-ui-block__array-label {
  font-weight: bold;
  font-size: 15px;
  font-style: italic;
}
.c-ui-block__array-label--total {
  color: $pulpocolor;
}
.c-ui-block__item-time {
  position: relative;

  & span {
    position: absolute;
    right: 18px;
  }
}
@media screen and (min-width: 1200px) {
  .c-ui-block__item-time {
    & span {
      right: 30px;
    }
  }
}

// column title
.c-col-title {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  text-align: center;
}

.total-reservation {
  background-color: $pulpocolor;
  color: #ffffff;
  padding: 4px 8px;
}

.book-blocks-margins {
  margin: 96px 0 96px 0;
}

// Traveler cpt
.c-book-traveler {
  border-top: 1px solid #d3d3d3;
  padding: 32px 0;

  &:first-child {
    border-top: none;
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}
.c-book-traveler--small {
  padding: 16px 0;
}
.c-book-traveler__label {
  display: inline-block;
  vertical-align: middle;
  width: 60px;
}
.c-book-traveler__label-icon {
  width: 30px;
  height: 30px;
  fill: $pulpocolor;
  margin-right: 4px;
}
.c-book-traveler__required-field {
  position: absolute;
  left: 12px;
  bottom: -40px;
  font-size: 12px;
  font-weight: bold;
  color: #ff6767;
}
.c-book-traveler__civility {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  margin-right: 10px;
}
.c-book-traveler__surname {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 120px;
  margin-right: 10px;
}
.c-book-traveler__name {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 120px;
  margin-right: 10px;
}
.c-book-traveler__birthday {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 120px;
}
@media (min-width: 1200px) {
  .c-book-traveler__label {
    margin-right: 10px;
  }
  .c-book-traveler__civility,
  .c-book-traveler__surname,
  .c-book-traveler__name {
    margin-right: 20px;
  }
  .c-book-traveler__civility {
    width: 150px;
  }
  .c-book-traveler__surname,
  .c-book-traveler__name,
  .c-book-traveler__birthday {
    width: 215px;
  }
}

// Flight cpt
.c-book-flight {
  border-top: 1px solid #d3d3d3;
  padding: 32px 0;

  &:first-child {
    border-top: none;
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}
.c-book-flight__info {
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
}

// Option ctp
.c-book-option {
  border-top: 1px solid #d3d3d3;
  padding: 32px 0;

  &:first-child {
    border-top: none;
    padding-top: 0;
  }
}
.c-book-option--small {
  padding: 16px 0;
}
.c-book-option--bottom-none {
  &:last-child {
    border-bottom: none;
  }
}
.c-book-option__item {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}
.c-book-option__input-buttons {
  width: 100px;
  text-align: center;
  margin-top: 12px;
  margin-left: 24px;
}
.c-book-option__input-button {
  background-color: $pulpocolor;
  color: #ffffff;
  border: none;
}

.blue-color {
  color: white !important;
  font-weight: bold;
  background-color: rgba(3, 94, 123, 0.55) !important;
}

.green-color {
  color: white !important;
  font-weight: bold;
  background-color: rgba(0, 100, 0, 0.55) !important;
}

.orange-color {
  color: white !important;
  font-weight: bold;
  background-color: rgba(205, 109, 0, 0.55) !important;
}

.green-color-light {
  background-color: rgba(0, 100, 0, 0.2) !important;
}

.blue-color-light {
  background-color: rgba(7, 0, 100, 0.2) !important;
}

.red-color-light {
  background-color: rgba(205, 0, 0, 0.2) !important;
}

.orange-color-light {
  background-color: rgba(205, 130, 0, 0.2) !important;
}

.sticky-button-bl {
  z-index: 100;
  position: fixed;
  bottom: 40px;
  right: 40px;
  transform: scale(1.25);

  .btn-enabled:hover {
    background-color: rgb(0, 202, 241) !important;
    box-shadow: rgb(172, 237, 255) !important;
    color: #fff;
  }

  .btn-enabled-red {
    background-color: rgb(241, 0, 0) !important;
    color: #fff;
  }

  .btn-enabled-red:hover {
    background-color: rgb(255, 146, 146) !important;
    box-shadow: rgb(255, 172, 172) !important;
    color: #fff;
  }
  .btn {
    width: 140px;
    height: 45px;
    border-radius: 45px;
  }
}

.fixed-button {
  height: 60px !important;
  width: 100px !important;
  border-radius: 5px;
}

.btn-blue {
  background-color: $pulpocolor !important;
  color: #fff !important;
  mat-icon {
    margin: 0 !important;
    scale: 1.5
  }
}

.btn-red {
  background-color: rgb(241, 0, 0) !important;
  color: #fff;
  mat-icon {
    margin: 0 !important;
    scale: 1.5;
  }
}

/*
* Bootstrap overrides https://getbootstrap.com/docs/4.0/getting-started/theming/
* All values defined in bootstrap source
* https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss can be overwritten here
* Make sure not to add !default to values here
*/

// Colors:
// Grayscale and brand colors for use across Bootstrap.

$primary: #3e8acc;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;

// Options:
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

// Components:
// Define common padding and border radius sizes and more.

$border-radius: 0.15rem;
$border-radius-lg: 0.125rem;
$border-radius-sm: 0.1rem;

// Body:
// Settings for the `<body>` element.

$body-bg: #fff;

// Typography:
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 1rem;

$main-color: #143580;

.custom-label {
  /* Styles for tab labels */
  .mat-tab-label {
    width: 250px !important;
    height: 60px !important;
    padding: 5px;
    background-color: var(--white);
    color: $pulpocolor;
    font-weight: 500;
    font-size: 16px;
  }

  /* Styles for the active tab label */
  .mat-tab-label.mat-tab-label-active {
    width: 250px !important;
    height: 60px !important;
    padding: 5px;
    background-color: $pulpocolor;
    color: var(--white);
    font-weight: 500;
    font-size: 18px;
    span {
      color: white;
    }
  }

  /* Styles for the ink bar */
  .mat-ink-bar {
    background-color: $pulpocolor;
  }
}

.centered-spinner {
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.main-loader {
  position: fixed !important;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  top: 0;
  left: 0;
  overflow: hidden;
}

.pulpo-border {
  border: 1px solid $pulpocolor !important;
  border-radius: 20px;
}

.pulpo-border-small-radius {
  border-radius: 10px !important;
}

.pulpo-row {
  border-bottom: 1px solid $pulpocolor !important;
}

.centered-span {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.no-scroll {
  overflow: hidden !important;
  height: 100% !important;
}

.error-snackbar {
  color: white;
  background-color: rgb(255, 46, 46);
  * {
    color: white;
    font-weight: bold;
  }
  min-width: 300px !important;
  width: 300px !important;
  position: absolute;
}

.pulpo-color {
  color: $pulpocolor !important;
}

.pulpo-divider {
  border-top: 1px solid $pulpocolor !important;
}

button.mat-calendar-body-cell {
  position: absolute;
  height: 100%;
}

td.mat-calendar-body-cell {
  position: relative;
  height: 0;
}